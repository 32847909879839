<template>
	<div id="tu-work">
		<el-row :gutter="20">
			<el-col :span="4">
		    <SecondMenu />
			</el-col>
			<el-col :span="20">
				<div class="global-search">
					<div class="global-search__title">{{ $t('search') }}</div>
					<div class="global-search__warp">
						<el-form :inline="true" class="global-search__warp__inline">
							<!-- <el-form-item :label="$t('status')">
						    	<el-select v-model="params.stat" placeholder="Select" clearable filterable>
									<el-option
										v-for="item, index in tab"
										:key="item.value"
										:label="$t(item.langName)"
										:value="item.value"
									/>
								</el-select>
							</el-form-item> -->
							<el-form-item v-if="!isStudent" :label="$t('select-student')">
								<el-select v-model="params.profile_id" placeholder="Select" clearable filterable>
									<el-option 
										v-for="item, index in studentList"
										:key="item.profile_id"
										:label="item.nickname"
										:value="item.profile_id"
									/>
								</el-select>
							</el-form-item>
							<el-form-item v-else :label="$t('select-teacher')">
								<el-select v-model="params.uid" placeholder="Select" clearable filterable>
									<el-option 
										v-for="item, index in teacherList"
										:key="item.uid"
										:label="item.nickname"
										:value="item.uid"
									/>
								</el-select>
							</el-form-item>
							<el-form-item>
								<el-button type="primary" @click="onSearchBtn">{{ $t('search') }}</el-button>
							</el-form-item>
						</el-form>
					</div>
				</div>
				<!-- <div class="tu-title">
					<div>作业列表</div>
					<div></div>
				</div> -->
				<div class="tu-table">
					<!-- <div class="tu-tab">
						<div v-for="item,index in tab" :key="index" @click="onSelectTab(item)" :style="{background: params.stat==item.value?'var(--el-color-success)':'var(--el-color-info)'}">
							{{ item.name }}
						</div>
					</div> -->
					<div class="tu-tab">
						<el-radio-group v-model="params.stat" :label="$t('status')" @change="onRadioStatChange">
							<el-radio-button v-for="item, index in tab" :label="item.value">{{ $t(item.langName) }}</el-radio-button>
						</el-radio-group>
					</div>
					<div v-if="tableData.length > 0 || loading">
						<el-table 
							v-loading="loading"
							element-loading-text="Loading..."
							:data="tableData" 
							border>
					    <el-table-column prop="date" :label="$t('Date & Time')">
					    	<template #default="scope">
									<div class="tu-date">{{ handleDateShow(scope.row) }}</div>
									<div class="tu-time">{{ handleTimeShow(scope.row) }}</div>
								</template>
					    </el-table-column>
					    <el-table-column prop="name" :label="$t('Name')">
					    	<template #default="scope">
									<div class="tu-nickname">{{ scope.row.user_info.nickname }}</div>
								</template>
					    </el-table-column>
					    <el-table-column prop="due" :label="$t('Due')">
					    	<template #default="scope">
									<div class="tu-nickname">{{ handleFormatShow(scope.row.expire_date) }}</div>
								</template>
					    </el-table-column>
					    <el-table-column prop="note" :label="$t('Note')" />
					    <el-table-column width="120" align="center" :label="$t('Operations')">
					      <template #default="scope">
					        <el-button type="primary" size="mini" @click="handleDetail(scope.$index, scope.row)">
					        	{{ $t('detail') }}
					        </el-button>
					      </template>
					    </el-table-column>
						</el-table>	
						<div class="tu-pagination">
							<el-pagination 
								:hide-on-single-page="true" 
								background 
								layout="prev, pager, next" 
								:total="params.total"
								:page-size="20"
								@current-change="handlePageChange">
							</el-pagination>
						</div>
					</div>
					<div v-else>
						<el-empty :description="$t('No Data')"></el-empty>
					</div>
				</div>
			</el-col>
		</el-row>
	</div>
</template>
<script>
import { mapActions, mapGetters } from 'vuex'
import SecondMenu from '@/components/SecondMenu'
import WorkApi from '@/api/work'
import TimeControl from '@/utils/time-control'

export default {
	name: "work",
	components: {
		SecondMenu
	},
	data() {
		return {
			loading: true,
			tableData: [],
			params: {
				uid: null,
				profile_id: null,
				page: 1,
				stat: 1,
				total: 0
			},
			tab: [
				{ name: '待上传作业', value: 1, langName: 'tab-status.job-to-upload' },
				{ name: '待审批', value: 2, langName: 'tab-status.pending' },
				{ name: '已完成', value: 3, langName: 'tab-status.completed' },
				{ name: '全部', value: 0, langName: 'tab-status.all' }
			],
			tabSet: null
		}
	},
	created() {
		this.getApiListData()
	},
	computed: {
		...mapGetters([
			'userInfo', 'userType', 'isStudent', 'studentList', 'teacherList'
		])
	},
	methods: {
		handleDetail(index, row) {
			let course_id = row.course.id || 0;
			if(!!!course_id) {
				this.$message.error(this.$t('exception.request'));
				return false;
			}
			this.$router.push({ path: `/course/${course_id}` })
		},
		handlePageChange(page) {
			this.loading = true
			this.params.page = page || 1;
			this.getApiListData();
		},
		getApiListData() {
			WorkApi.index(this.params).then((res) => {
				this.loading = false
				this.tableData = res.data
				this.params.total = res.meta.pagination.total
			});
		},
		handleDateShow(row) {
			let book_time = row.course.book_time * 1000;
			return TimeControl.getFormatTime(book_time)
		},
		handleTimeShow(row) {
			let book_time = row.course.book_time * 1000;
			let book_end_time = (row.course.book_time + row.course.second) * 1000;
			return TimeControl.getFormatTime(book_time, "HH:mm") + ' ~ ' + TimeControl.getFormatTime(book_end_time, "HH:mm")
		},
		handleFormatShow(value) {
			if(value == 0) {
				return '--';
			}
			return TimeControl.getFormatTime(value, 'L');
		},
		// onSelectTab(value) {
		// 	this.params.stat = value.value;
		// 	this.params.page = 1;
		// 	this.loading = true;
		// 	this.getApiListData();
		// },
		onSearchBtn() {
			this.params.page = 1;
			this.loading = true;
			this.getApiListData();
		},
		onRadioStatChange() {
			if(!!this.tabSet) {
				clearTimeout(this.tabSet);
			}
			this.tabSet = setTimeout(() => {
				this.params.page = 1;
				this.loading = true;
				this.getApiListData();
			}, 500)
		}
	}
}
</script>
<style lang="less">
#tu-work {
	.tu-title {
		background: var(--el-color-primary);
		padding: 17px 15px;
		color: var(--el-color-white);
		text-align: left;
		font-weight: 700;
		display: flex;
		flex-direction: row;
		justify-content: space-between;
		align-items: center;
	}

	.tu-pagination {
		padding: 20px 0;
	}

	.tu-tab {
		margin-bottom: 10px;
		// display: flex;
		// align-items: center;
		// justify-content: space-around;
		// margin: 10px 0;

		// & > div {
		// 	width: 30%;
		// 	text-align: center;
		// 	padding: 10px 0;
		// 	border-radius: 5px;
		// 	background: var(--el-color-info);
		// 	color: #FFF;
		// 	cursor: pointer;

		// 	-moz-user-select:none; /*火狐*/
		// 	-webkit-user-select:none; /*webkit浏览器*/
		// 	-ms-user-select:none; /*IE10*/
		// 	user-select:none;
		// }
	}

	.tu-table {
		.tu-nickname {
			color: var(--el-color-primary);
			font-size: 16px;
			font-weight: 600;
			padding: 6px 0;
		}

		.tu-subject, .tu-time, .tu-status {
			color: var(--el-color-info);
		}

		.tu-date {
			color: var(--el-color-primary);
			font-size: 16px;
			font-weight: 400;
			padding: 6px 0;
		}

		.tu-price {
			color: var(--el-color-primary);
			font-size: 16px;
			font-weight: 400;
			padding: 6px 0;
		}
	}
}
</style>